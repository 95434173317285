<template lang="pug">
  .price-table
    table
      thead
        tr
          th.header-item.sticky-col.status.sortable(@click="handleSorting('status')")
            span {{ $t("plan_management.table.columns.status") }}
            FaIcon.icon(:icon="sortingIcon('status')")
          th.header-item.sticky-col.ota-name.sortable(@click="handleSorting('ota_name')")
            span {{ $t("plan_management.table.columns.ota_name") }}
            FaIcon.icon(:icon="sortingIcon('ota_name')")
          th.header-item.sticky-col.plan-name.sortable(@click="handleSorting('name')")
            span {{ $t("plan_management.table.columns.plan_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.header-item.sticky-col.insurance.sortable(@click="handleSorting('insurance')")
            span {{ $t("plan_management.table.columns.insurance") }}
            FaIcon.icon(:icon="sortingIcon('insurance')")
          th.header-item.sticky-col.sale-start-date.sortable(@click="handleSorting('sale_start_date')")
            span {{ $t("plan_management.table.columns.sale_start_date") }}
            FaIcon.icon(:icon="sortingIcon('sale_start_date')")
          th.header-item.sticky-col.sale-stop-date.sortable(@click="handleSorting('sale_end_date')")
            span {{ $t("plan_management.table.columns.sale_stop_date") }}
            FaIcon.icon(:icon="sortingIcon('sale_end_date')")
          th.header-item.sticky-col.posting-start-date
            span {{ $t("plan_management.table.columns.posting_start_date") }}
          th.header-item.sticky-col.posting-end-date
            span {{ $t("plan_management.table.columns.posting_end_date") }}
          th.header-item.sticky-col.car-class.sortable(@click="")
            span {{ $t("plan_management.table.columns.car_class") }}
          th.header-item.sticky-col.base-rate-period-group
            span {{ $t("plan_management.table.columns.base_rate_period_group") }}
          th.header-item.sticky-col.prices
            span {{ $t("plan_management.table.columns.price") }}
      tbody
        template(v-for="plan in plans")
          PlanRow(:plan="plan")
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      PlanRow: () => import("./PlanRow")
    },

    mixins: [withSorting],

    props: {
      sortingData: Object,
      plans: {
        type: Array,
        default: () => new Array()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .price-table
    +listing-container(10px)
    position: relative
    overflow: auto

    thead
      th
        +custom-table-th

        &.sticky-col
          +sticky-col
          top: 0
          z-index: 10

        &.header-item
          +cell-width(286px)
          align-items: center
          span
            width: 260px

          &.status
            left: 0
            z-index: 11
          &.ota-name
            left: 100px
            z-index: 11
          &.plan-name
            left: 200px
            z-index: 11

          &.status
            +cell-width(100px)
          &.ota-name
            +cell-width(100px)

          &.plan-name
            +cell-width(200px)

          &.insurance
            +cell-width(100px)

          &.sale-start-date,
          &.sale-stop-date,
          &.posting-start-date,
          &.posting-end-date
            +cell-width(140px)

          &.car-class
            +cell-width(30px)
          &.base-rate-period-group
            +cell-width(55px)
</style>
